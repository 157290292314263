<template>
  <div class="pageContainer" id="ranglisteBG" >
    
    <AliLogo></AliLogo>

    <div id="TTC" class="container-fluid dkm-bg d-flex" style="padding: 25vh 0 1vh 8vw; flex-direction: column;align-items: stretch;">
                <h2 class="h2">Jedes Watt zählt!</h2>
                <h1 class="h1">Unsere Top-Ten:  </h1>
        <TopTen 
          v-if="ok==true"
          :eventName="'allianz'" 
          id="irgendwas" 
          key="topten"
          class="scores"></TopTen>
    </div>
  </div>
</template>

<script>
import TopTen from '@/components/highscore/TopTen.vue'; // @ is an alias to /src
import AliLogo from '@/views/windpark/components/AllianzLogo.vue'; // @ is an alias to /src

import {mapGetters} from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'SetUp',

  components: {
    TopTen,
    AliLogo
  },

  data() {
    return {
            ok: false,
    }
  },

  created() {
  },

  async mounted() {
    await this.getEvents()
    this.ok = true
  },

  computed: {
     ...mapGetters([
    ]),
  },

  methods: {
    ...mapActions([
      'getEvents',
    ]),
  },
}
</script>

<style lang="scss">

body{
  background-color: rgba(255,255,255,0);
}

    #toast-container{
        display:none!important;
        }
        
    #ranglisteBG {
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 50%), url(/img/allianzWindpark/Background_gradient_FHD.png);
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0;
        width: 100vw;
    }

    #JedesWattzaehlt {
        background: url(/img/allianzWindpark/JedesWattzaehlt.png);
        background-repeat: no-repeat;
        background-size: cover;
        margin: 5vw;
        width: 15vw;
        height: 15vw; 
        right: 0;
    }
    .dkm-main {
       flex-grow: .6;
       top:20vh;
       top: 1vw;
    }
    
    .dkm-header, .dkm-main, .dkm-footer {
        flex-shrink: 0;
    }
    .h1 {
        color: #00908D;
        font-size: 100px;
        line-height: 1;
        font-weight: 400;
        margin: 0;
    }
    .h2 {
        color: #003781;
        font-size: 48px;
        line-height: 1;
        font-weight: 400!important;
        margin: 1vh;
        display: block;
    }
    .dkm_logo {
        width: 88px;
    }

.ttRow {
  margin: 10vh 0 0 0;
}
    
#irgendwas{
  .highscoreContainer {
    
    width: 100%;
    font-size: 1rem; 
    color: #fff;

    .hs-row {
      width: 100%;
      display: flex;
      padding: 0.3vw 1.4rem;
      font-size: 1.5rem;
      color: #003781;
      background-color:white;
      margin-top: 0.7vh;
      font-family: Allianz Neo;
      font-style: normal;
      font-weight: normal;
    } 

    .top10{
      width: 100%;
    }

  }

  div {
    font-size: 2.5vh;
  }

  #TTC {
    margin: 0 0 0 15vw;
    padding: 5vh 1vw 0 0 vw;
  }

  .hs-row:first-of-type {
    background-color: #00908D;
    color: white;
  }

  .hs-index {
      width: 25%;
  }

  .hs-playerName {
    width: 45%;
    }

  .hs-playerPoints {
    width: 25%;
  }

}

.scores {
	width: 50%;
  margin: 4vh 0; 
}

.eventHeadline {
	display: none;
}

</style>
